import React from 'react';

import Logo from './../../assets/Logo.svg';

const Landing = () => {
    return (
        <div id="landing-section" className='landing_wrapper'>
            <div>
                <img className='landing-logo' src={Logo}/>
            </div>
        </div>
    )
}

export default Landing;