import React from 'react';

const Information = () => {

    return (
        <div id="information-section" className='information_wrapper'>
            <h2>INFORMATION OM GRÅ STARR</h2>
                <p>
                    Grå starr är en ögonsjukdom som är vanlig och vanligtvis beror på kroppens åldrande. Grå
                    starr betyder att ögats lins blir grumlig och gör att synen blir sämre. Grå starr behandlas med
                    en operation, vid operationen byts kroppens egen lins ut mot en lins av plast.
                </p>
                <div className='symptom-list_wapper'>
                    <p>
                        De vanligaste symtomen på gråstarr är: 
                    </p>
                    <ul className='symptom-list'>
                        <li>Suddigare syn</li>
                        <li>Svårt att se i mörker</li>
                        <li>Färger blir svagare och mer gulbruna</li>
                        <li>Bländningsbesvär och känslighet för starkt ljus, till exempel mötande strålkastare vid körning i mörker</li>
                        <li>Konturer blir svagare</li>
                        <li>Dubbelsyn</li>
                    </ul>
                </div>


                <h3>UNDERSÖKNING</h3>
                    <p>
                        För att ställa diagnosen grå starr undersöks ögonen i ett ögonmikroskop. 
                        Innan undersökningen används ögondroppar som vidgar pupillen.
                    </p>
                    <p>
                        Med ögonmikroskopet kan vi se om linsen är grumlig. Man undersöker hela ögat för att se
                        om finns några andra
                        orsaker till synnedsättningen. Om andra orsaker till synnedsättningen
                        finns hjälper vi dig att komma till rätt
                        vårdinstans.
                    </p>
                    <p>
                        Det är vanligast att båda ögonen opereras: ibland opereras det andra ögat inom några
                        veckor efter det första ögat,
                        eller på samma dag som det första ögat. I en del fall kan man
                        vänta med att operera det andra ögat.
                    </p>


                <h3>GRÅ STARROPERATIONEN</h3>
                    <p>
                        Vid operationen används ögondroppar som bedövar ögat och vidgar pupillen.
                    </p>
                    <p>
                        Under operationen ligger man i en speciell stol. Stolen liknar den som används hos
                        tandläkare. Innan
                        operationen tvättar en sjuksköterska huden runt ögat och ögat sköljs
                        noga. En tunn duk läggs över ansiktet och
                        en speciell hållare som hjälper till att hålla ögat
                        öppet under operationen sätts på plats. Ett kraftigt ljus lyser på
                        ögat, ljuset bländar dig och
                        du ser inte instrumenten som används under operationen.
                    </p>
                    <p>
                        Ögonläkaren gör ett litet snitt i utkanten av hornhinnan. Därefter delas linsen och tas bort
                        med hjälp av ultraljud.
                        Sedan sätts den nya konstgjorda linsen in i ögat. Under operationen
                        spolas ögat hela tiden med vätska. Snittet i
                        hornhinnan läker ihop av sig själv efteråt.
                    </p>
                    <p>
                        Det är ganska starkt ljus under operationen, men operationen gör inte ont. Operationen tar
                        oftast ungefär tio till
                        femton minuter. Ibland kan det ta längre tid, men kan även gå
                        snabbare.
                    </p>


                        <h4>OPERATIONSDAGEN</h4>
                            <p>
                                Ät och drick som vanligt och ta dina vanliga mediciner. Om man känner sig orolig inför
                                operationen kan man få lugnande läkemedel. Sammanlagt tar det ca 1,5-2 timmar för
                                undersökning/förberedelse inför operationen, operationen samt vila efteråt.
                            </p>

                        <h4>EFTER OPERATION</h4>
                            <p>
                                Det är vanligt att synen är disig och färger kan upplevas som mer blå den första tiden efter
                                operationen. Det
                                skaver ofta i ögat upp till någon månad efter operationen. Ögat kan också
                                vara lite rött och mer ljuskänsligt.
                            </p>
                            <p>
                                Du ska kontakta oss om synen blir mycket sämre eller om ögat blir kraftigt rött eller gör ont. 
                                Undvik att köra bil tills synen har förbättrats.
                            </p>
                            <p>
                                Man kan oftast leva som vanligt. Vid behov kan man sjukskrivas. 
                            </p>
                            <p>
                                Man ska inte gnugga ögat och undvika vatten i ögat 10 dagar efter operationen. Smutsiga
                                miljöer, offentliga bad och hård fysisk träning ska undvikas 3 veckor efter operationen.
                                Använd inget smink på 2 veckor.
                            </p>

                        <h4>ÖGONDROPPAR</h4>
                            <p>
                                De närmaste veckorna efter operationen behöver det opererade ögat behandlas med
                                ögondroppar. Dropparna innehåller ett inflammationsdämpande ämne, eller kortison.
                                Läkemedlet minskar risken för att det blir en inflammation i ögat efter operationen. Har du
                                andra ögondroppar som du tar innan operationen, ska dessa tas som vanligt även efter operationen.
                            </p>
                            <p>
                                De flesta kan ta ögondropparna själv. Be en närstående om hjälp eller prata med en
                                distriktssköterska på vårdcentralen om du tror att du kommer att behöva hjälp med att ta ögondropparna
                            </p>

                        <h4>ÅTERBESÖK</h4>
                            <p>
                                Behövs det en kontroll efter operationen bokas det vid hemgång. Om det andra ögat ska
                                opereras, kontrolleras det första ögat i samband med operationen. Det behövs inget
                                återbesök om operationen gått som den ska och det inte finns någon annan ögonsjukdom.
                            </p>
                            <p>
                                Sex veckor efter operationen behöver du prova ut nya glasögon. Det vanligaste är att man
                                enbart behöver läsglasögon efter operationen, men om man har brytningsfel (astigmatism)
                                kan man behöva glasögon även på långt håll. Detta kan diskuteras med kirurgen vid besöket
                            </p>
                    <h3>KOMPLIKATIONER</h3>
                        <p>
                        Komplikationer efter operation för grå starr är ovanliga.
                        </p>
                        
                        <h4>HÖGT ÖGONTRYCK, SVULLNAD AV HORNHINNA OCH GULA FLÄCKEN</h4>
                            <p>
                                I vissa fall kan ögontrycket stiga efter operationen. I en del fall kan hornhinnan eller gula
                                fläcken svullna. Dessa tillstånd kan behandlas med ögondroppar eller tabletter och läker i de allra flesta fall helt.
                            </p>

                        <h4>EFTERSTARR</h4>
                            <p>
                                Ibland bildas det en grumlig hinna bakom plastlinsen. Det kallas för efterstarr och kan
                                komma månader eller år efter operationen. Behandling av efterstarr är ett enkelt ingrepp med laser.
                            </p>

                        <h4>SYNFENOMEN</h4>
                            <p>
                                Efter operationen kan man tillfälligt se ljusstrålar kring lampor och ha svårare att se kontraster. 
                                Det brukar gå över efter en tid och är ovanligt att besvären finns kvar under en längre tid.
                            </p>

                        <h4>INFEKTION</h4>
                            <p>
                                Det finns en liten risk för infektion efter en operation i ögat. Det gör det vid alla operationer.
                                Det är cirka ett öga av åttatusen opererade ögon som får infektion. En sådan ögoninfektion gör att synen blir sämre.
                            </p>
        </div>
    ) 
}

export default Information;