import React from 'react';

const About = () => {

    return (
        <div id="about-section" className='about_wrapper'>
            <h1>GRÅ STARRKIRURGI - VÅR SPECIALITET</h1>
            <p className='center'>
                Vår ögonklinik i Lund utför enbart grå starroperationer: vi har modern utrustning, gedigen
                erfarenhet samt hög tillgänglighet med korta väntetider. Vi sätter patienterna i centrum och
                vårt främsta mål är att du som patient ska bli nöjd. Vi har ett mycket nära samarbete med
                Ögoncentrum Annedal och har därför möjlighet att hjälpa patienter även med andra
                ögonsjukdomar.
            </p>
            <p className='center'>
                Vi utför vårt arbete i samarbete med Region Skåne som en del av vårdvalet för grå starr. Vi
                tillämpar samma avgifter som Region Skåne för offentlig vård, frikort och högkostnadskort
                gäller. Gratis sjukvård för barn och ungdomar under 20 år samt för alla över 85 år.
            </p>
        </div>
    )
}

export default About;