import React from 'react';

const Gdpr = () => {

    return (
        <div id="gdpr-section" className='gdpr_wrapper'>
            <h2>DATASKYDDSFÖRORDNINGEN GDPR OCH PATIENTDATALAGEN</h2>
                <p>
                    Vi arbetar i enighet med den nya dataskyddsförordningen, GDPR (General Data Protection
                    Regulation). För mer information kring GDPR hänvisas till Datainspektionen.
                </p>
                <p>
                    Inom vården följer vi den så kallade Patientdatalagen, vilket innebär att vi för att kunna hjälpa våra
                    patienter på bästa sätt måste spara de personuppgifter som anses nödvändiga för att kunna ge en god och säker vård.
                </p>
                <p>
                    Vårt arbete på ALB Gråstarrkirurgi utgår med största respekt för din personliga integritet och vi följer
                    alltid gällande lagar och förordningar.
                </p>

                <div className='data-collection-list_wapper'>
                    <p>
                        Dina personuppgifter kan samlas in på följande sätt:
                    </p>
                    <ul className='data-collection-list'>
                        <li>
                            Du själv lämnar dem till oss, exempelvis via egen vårdbegäran, i kontakt med vår
                            telefonrådgivning, via e-post, brev och kontaktformulär på hemsidan
                        </li>
                        <li>
                            Genom att vi får uppgifter från dina vårdkontakter i samband med att remisser utställs till oss
                            som vårdgivare
                        </li>
                    </ul>
                    <p>Vi sparar inte personuppgifter om dig när du besöker vår hemsida.</p>
                </div>

                <h3>VILKA PERSONUPPGIFTER BEHANDLAS, HUR LÄNGE OCH VARFÖR?</h3>
                    <p>
                        De personuppgifter som behandlas är nödvändiga för att kunna ge en god och säker vård. De kan även vara kravställda från våra uppdragsgivare och nödvändiga för att bedriva
                        verksamheten på ett adekvat sätt. Detta kan innefatta medicinska uppgifter i din journal samt patientadministrativa uppgifter såsom personnummer, namn, postadress, telefonnummer och ev. kontaktperson. I samband med ett besök hos oss behandlas även uppgifter
                        om besökstiden, besökstyp samt vårdgivare. Vi hanterar och sparar alla dina uppgifter efter de regler som finns i
                        Dataskyddsförordningen och Patientdatalagen, dvs. så länge det finns ett behov utifrån ändamålet och minst så
                        länge som lagen kräver. Uppgifter i patientjournaler sparas i minst tio år.
                    </p>

                <h3>DINA RÄTTIGNHETER</h3>
                    <p>
                        Som patient har du rätt att ta del av uppgifter som finns hos oss. Du har även rätt att få en kopia av samtliga
                        uppgifter. Patientjournalen skyddas av hälso- och sjukvårdssekretessen. Det innebär att uppgifter i
                        patientjournalen aldrig lämnas ut till obehöriga. Du kan också begära rättning av eventuella felaktigheter, tycka
                        till, ställa en fråga eller lämna en kommentar om hur vi hanterar dina uppgifter.
                    </p>

                <h3>VILKA KAN VI KOMMA ATT DELA DINA PERSONUPPGIFTER MED?</h3>
                    <p>
                        Dina personuppgifter kan komma att delas med personuppgiftsbiträden för att fullgöra våra åtaganden, exempelvis journalsystem eller bokningssystem som sköts av en extern leverantör. Skriftliga avtal finns med alla personuppgiftsbiträden genom vilka de garanterar säkerheten för de personuppgifter som behandlas.
                    </p>
                    <p>
                        Dina personuppgifter kan även delas med vissa parter som vi delar personuppgiftsansvaret med. I dessa fall gäller det företagets integritetspolicy och personuppgiftshantering när de hanterar dina personuppgifter. 
                    </p>

                    <div className='data-parties-list_wapper'></div>
                        <p>
                            Exempel på sådana parter är:
                        </p>
                        <ul className='data-parties-list'>
                            <li>Statliga myndigheter</li>
                            <li>Företag som erbjuder betallösningar (när du betalar med betalkort hos oss)</li>
                            <li>Vissa vårdenheter, laboratorium eller försäkringsbolag</li>
                        </ul>
                        <p>
                            Varje organisation vi lämnar ut uppgifter till ansvarar för att förvalta dina personuppgifter säkert och enligt lagen.
                        </p>

                <h3>KLAGOMÅL</h3>
                    <p>
                        Om personuppgifter om dig har behandlats så att de strider mot Dataskyddsförordningen har du rätt att när
                        som helst ta kontakt med oss och inkomma med klagomål. Vi kommer då att utreda det inträffade och åtgärda de fel eller brister klagomålet avser. 
                        För personuppgiftsincidenter gäller anmälningsplikt till Datainspektionen enligt GDPR.
                    </p>

                <h3>NATIONELLA OCH REGIONALA KVALITETSREGISTER</h3>
                    <p>
                        Inom hälso- och sjukvården finns kvalitetsregister som används för att systematiskt och fortlöpande utveckla och säkra vårdens kvalitet. 
                        Som patient får du information om du registreras. Du har även rätt att neka till att bli registrerad i ett kvalitetsregister samt rätt att i efterhand stryka dig från registret.
                    </p>

                <h3>VEM ÄR ANSVARIG FÖR DINA PERSONUPPGIFTER?</h3>
                    <p>
                        Bartuma Eye Care AB, org.nr 559173-8223, är personuppgiftsansvarig för behandlingen av dina personuppgifter.
                        Dataskyddsombud har utsetts. Alla ärenden avseende dina patientuppgifter besvaras inom 30 dagar.
                    </p>
        </div>
    ) 
}

export default Gdpr;