import React, { useState } from 'react';

//icon imports
import PhoneIcon from './../../assets/phone-icon.svg';
import MailIcon from './../../assets/mail-icon.svg';
import CalanderIcon from './../../assets/calendar-icon.svg';

const Contact = ({
    isMobile
}) => {
    const [hrefForm, setHrefForm] = useState("mailto:info@albklinkiken.se");

    
    const setFormDetails = () => {
        let formName = document.getElementById('form-name').value;
        let formEmail = document.getElementById('form-email').value;
        let formNumber = document.getElementById('form-number').value;
        let formMessage = document.getElementById('form-message').value;
        setHrefForm("mailto:info@albkliniken.se?subject=Kontaktformul%C3%A4r&body=Namn%3A%20" + formName + "%0A%0AE-post%3A%20" + formEmail + "%0A%0ANummer%3A%20" + formNumber + "%0A%0A" + formMessage);
    }
    
    return (
        <div id="contact-section" className='contact_wrapper'>
            <h1>KONTAKTA OSS</h1>

            
            <p className='center'>
                Vi är belägna på Annedalsvägen 9a i Lund, i lokaler på bottenvåningen bredvid Ögoncentrum Annedal.
                Det finns gratis parkering utanför kliniken. Stadsbuss nr 2 från Lund C mot Nova stannar vid Annedalsvägen, endast ca 200 m från kliniken.
            </p>
            { isMobile ? 
                <a className='location-link_wrapper' href={'https://maps.app.goo.gl/zCipLZK1LVh6ohhY8'}>
                    <a className='location-link'>ÖPPNA I KARTOR</a>
                </a>
                : 
                <iframe className='map-embed' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2247.599444947433!2d13.167679600000001!3d55.713334!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4653bd7bb7eaaaab%3A0x1180e2d7ae9981da!2sAnnedalsv%C3%A4gen%209A%2C%20227%2064%20Lund%2C%20Sweden!5e0!3m2!1sen!2suk!4v1719310440000!5m2!1sen!2suk"    allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>}
            
            {   !isMobile &&
                <div className='contact-info_wrapper'>
                    <div className='contact-info'>
                        <a href={"tel:0760-211340"}>
                            <img  className='contact-info-img' src={PhoneIcon}/>
                        </a>
                        
                        <div className='contact-info-text'>0760-211340</div>
                    </div>
                    <div className='contact-info'>
                        <a href={"mailto:info@albkliniken.se"}>
                            <img className='contact-info-img' src={MailIcon}/>
                        </a>
                        <div className='contact-info-text'>info@albkliniken.se</div>
                    </div>
                    <div className='contact-info'>
                        <img className='contact-info-img' src={CalanderIcon}/>
                        <div className='contact-info-text'>Måndag - Fredag 08:00-10:00</div>
                    </div>
                </div>
            }
            
            <p className='center'>
                Vill du boka tid, boka om eller har andra vårdrelaterade frågor kontakta vår ögonklinik i Lund via vårt kontaktformulär, eller telefonledes under våra öppettider. Om vi inte svarar i telefonen vänligen tala in ett meddelande på telefonsvararen så kontaktar vi dig.
            </p>

            {   isMobile &&
                <div className='contact-info_wrapper'>
                    <div className='contact-info'>
                        <a href={"tel:0760-211340"}>
                            <img  className='contact-info-img' src={PhoneIcon}/>
                        </a>
                        
                        <div className='contact-info-text'>0760-211340</div>
                    </div>
                    <div className='contact-info'>
                        <a href={"mailto:info@albkliniken.se"}>
                            <img className='contact-info-img' src={MailIcon}/>
                        </a>
                        <div className='contact-info-text'>info@albkliniken.se</div>
                    </div>
                    <div className='contact-info'>
                        <img className='contact-info-img' src={CalanderIcon}/>
                        <div className='contact-info-text'>Måndag - Fredag 08:00-10:00</div>
                    </div>
                </div>
            }
            
            <div className='contact-form_wrapper'>
                <label>Namn</label>
                <input id="form-name" type="text" name="contact_name" onKeyUp={setFormDetails}/>
                <label>E-Post</label>
                <input id="form-email" type="email" name="contact_email" onKeyUp={setFormDetails}/>
                <label>Tel Nummer</label>
                <input id="form-number" type="tel" name="contact_email" onKeyUp={setFormDetails}/>
                <label>Meddelande</label>
                <textarea id="form-message" name="message" onKeyUp={setFormDetails}/>
                <a className='contact-form-send' href={hrefForm}>SKICKA</a>
            </div>

        </div>
    )
}

export default Contact;