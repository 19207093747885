import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import './index.scss';

//component imports
import Landing from './components/landing/Landing.js';
import Header from './components/layout/header/Header.js';
import MobileMenu from './components/layout/mobileMenu/MobileMenu.js';
import About from './components/about/About.js';
import Information from './components/information/Information.js';
import Contact from './components/contact/Contact.js';
import Gdpr from './components/gdpr/Gdpr.js';


let isMobile = false;
let initialOffSet;

const handleResize = () => {
  isMobile = window.innerWidth <= 768;
  initialOffSet = window.innerHeight;
}

window.onresize = handleResize();

let headerOffSet;
let setHeaderOffSet = () => {
    headerOffSet = document.getElementById("header").offsetTop;
}

let header;
let setHeader = () => {
    header = document.getElementById("header");
}

let handleStickyHeader = (initialOffSet) => {
  if(window.pageYOffset >= initialOffSet) {
      header.classList.add("sticky");
  } else {
      header.classList.remove("sticky");
  }
}

if(isMobile == false){
  window.onscroll = () => {
      handleStickyHeader(headerOffSet);
  }
}

const routes = (
  <BrowserRouter>
      <div>
        <Landing/>
        { isMobile 
            ?
                <MobileMenu/>
            :
                <Header
                    setHeaderOffSet={setHeaderOffSet}
                    setHeader={setHeader}
                />
        }
        <About/>
        <Information/>
        <Contact
          isMobile={isMobile}
        />
        <Gdpr/>
      </div>
  </BrowserRouter>
);

ReactDOM.render(routes, document.getElementById('root'));