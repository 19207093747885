import React, { useEffect } from 'react';
import { HashLink as NavLink } from 'react-router-hash-link';
import { scrollToTop, scrollTo } from './../../../util/scrollHelper';

//Icon Imports
import SiteLogo from './../../../assets/Logo.svg';



const Header = ({
    setHeaderOffSet,
    setHeader
}) => {
    
    useEffect(() => {
        setHeader();
        setHeaderOffSet();
    });

    return (
        <div id="header" className='header_wrapper'>
            <NavLink id="#nav-landing" to="#landing-section" className='header-logo_wrapper' onClick={scrollToTop}>
                <img className='header-logo' src={SiteLogo} />
            </NavLink>
            <div className='header-nav_wrapper'>
                <NavLink id="#nav-about" to="#about-section" className='header-nav-link' onClick={scrollTo}>OM OSS</NavLink>
                <NavLink id="#nav-information" to="#information-section" className='header-nav-link' onClick={scrollTo}>INFORMATION</NavLink>
                <NavLink id="#nav-contact" to="#contact-section" className='header-nav-link' onClick={scrollTo}>KONTAKTA OSS</NavLink>
                <NavLink id="#nav-gdpr" to="#gdpr-section" className='header-nav-link' onClick={scrollTo}>GDPR</NavLink>
            </div>
        </div>
    )
}

export default Header;